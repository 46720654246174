import {
  createSlice,
  createAsyncThunk,
  isAnyOf,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const PATH = API_ROUTES.surveyLookupByLocation;
const API_KEY = apiConfig.apiKey;

// Set up initial state
const initialState = {
  loading: false,
  search: [],
  error: null,
};

export const searchByStore = createAsyncThunk(
  'survery/searchSurvey',
  async (store) => {
    const response = await axios.get(`${RYDER_HOST}${PATH}${store}`, {
      headers: {
        // headers
        'x-api-key': API_KEY, // 'x-api
      },
    });
    return response.data;
  },
);

export const searchByStoreExt = createAsyncThunk(
  'survery/getSurveryExt',
  async (location) => {
    const { store, token } = location;
    const response = await axios.get(`${EXTERNAL_HOST}${PATH}${store}`, {
      headers: {
        // headers
        'x-api-key': API_KEY, // 'x-api
        Authorization: token,
      },
    });
    return response.data;
  },
);

// Create slice logic
export const surveySearchSlice = createSlice({
  name: 'surveyByLocation',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(
        isAnyOf(searchByStore.fulfilled, searchByStoreExt.fulfilled),
        (state, action) => {
          state.loading = false;
          state.error = null;
          state.search = action.payload;
        },
      )
      .addMatcher(
        isAnyOf(searchByStore.pending, searchByStoreExt.pending),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(searchByStore.rejected, searchByStoreExt.rejected),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});

// Actions are generated for each reducer function
export default surveySearchSlice.reducer;

export const getDeviceStore = (state) => state.surveyByLocation.search;

export const getSurveyLocationDetails = createSelector(
  getDeviceStore,
  (vestibule) => {
    return vestibule;
  },
);
