import {
  Card,
  Grid,
  Layout,
  Breadcrumbs,
  Heading,
  Spinner,
  Divider,
} from '@enterprise-ui/canvas-ui-react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as ReactLink, useSearchParams } from 'react-router-dom';
import { ImageCard } from '../../components/ImageCard';

import { useAuth } from '@praxis/component-auth';
import { VestibuleDetailsCard } from '../../components/VestibuleDetailsCard';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';
import {
  searchByStore,
  searchByStoreExt,
  getSurveyLocationDetails,
} from '../../app/presurvey/surveySearchSlice';

export const SurveyDetails = () => {
  const [searchParams] = useSearchParams();
  const store = searchParams.get('store');
  const currentId = searchParams.get('id');
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.surveyByLocation);

  const auth = useAuth();
  const { user } = useInstallerAuthContext();
  const { session } = auth;

  useEffect(() => {
    if (store !== null) {
      if (session) {
        dispatch(searchByStore(store));
      } else if (user) {
        let { token } = user;
        dispatch(searchByStoreExt({ store, token }));
      }
    }
  }, [dispatch, store, session, user]);

  const reader = useSelector(getSurveyLocationDetails);
  //Filters payload for specific details on clicked vestibule (using '===' instead of '==' results in null)
  // eslint-disable-next-line eqeqeq
  const results = reader.filter((entry) => entry.id == currentId);

  return (
    <>
      <Layout.Body data-testid="homePage" includeRail>
        <Card className="hc-pa-normal">
          <Grid.Container>
            <Breadcrumbs className="breadcrumbSpacing">
              <Breadcrumbs.Item as={ReactLink} to={`/locationList/?q=${store}`}>
                {store}
              </Breadcrumbs.Item>
              <Breadcrumbs.Item as={ReactLink} to="/surveySearch">
                Vestibule Details
              </Breadcrumbs.Item>
            </Breadcrumbs>
            <Grid.Item xs={12}>
              <Heading size={3}>Device List</Heading>
            </Grid.Item>
            {loading && <Spinner size="sm" />}
            {!loading && !error && (
              <Grid.Container
                align="center"
                justify="center"
                className="hc-pa-expanded"
              >
                <>
                  <Grid.Item xs={12} data-testid="vestibuleDetails">
                    <VestibuleDetailsCard
                      data-testid="vestibuleDetails"
                      data={results[0]}
                    />
                  </Grid.Item>
                  <Heading size={4} className=" hc-pb-normal">
                    Uploaded Images
                  </Heading>
                  <Divider className="hc-ph-expanded" />
                  {results?.[0]?.vestibule_details?.attachment_links?.map(
                    (photo) => {
                      return (
                        <Grid.Item>
                          <ImageCard
                            className="hc-mb-normal"
                            data-testid="imageCard-1"
                            imageLink={photo}
                          />
                        </Grid.Item>
                      );
                    },
                  )}
                </>
              </Grid.Container>
            )}
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
