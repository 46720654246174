import { useRef, useEffect, useState, useCallback } from 'react';
import * as d3 from 'd3';
import { selectMapByStore } from '../app/neptune/neptuneSlice';
import { useSelector } from 'react-redux';
import { hideDeptNames } from '../utils/tagCalibrationHelpers';
import { useInstallerAuthContext } from '../hooks/useInstallerAuthContext';
import { createVestibuleVectors } from '../utils/surveyHelpers';
import './styles/neptuneMapStyles.scss';

export const SurveyMap = (storeId, coordinates) => {
  const [vestibuleVectors, setVestibuleVectors] = useState([]);
  const { user } = useInstallerAuthContext();
  const svgWrapper = useRef(null);
  const mapSelector = useSelector((state) =>
    selectMapByStore(state, storeId?.storeId, storeId?.floorNum),
  );

  const zoom = d3
    .zoom()
    .scaleExtent([1, 3])
    .extent([
      [0, 0],
      [150, 100],
    ])
    .translateExtent([
      [0, 0],
      [150, 100],
    ]); // Set zoom scale + translate limits

  const d3Map = d3.select(svgWrapper.current);

  const handleZoom = function (e) {
    d3Map.select('#content').attr('transform', e.transform); // Select main g DOM group to transform
  };

  const focusOnVestibule = useCallback(
    (coordinates) => {
      let { x, y } = coordinates;
      d3Map
        .transition()
        .duration(500)
        .call(
          zoom.transform,
          d3.zoomIdentity
            .translate(150, 100)
            .scale(3)
            .translate(-x + -30, -y + -10), // Takes into account neg values in viewbox from svg map
        );
    },
    [d3Map, zoom],
  );

  zoom.on('zoom', handleZoom); // Function to call on zoom
  d3Map.call(zoom); // Apply zoom behavior to the SVG map

  const addIconToMap = (vector) => {
    if (svgWrapper.current !== null) {
      let svgElem = svgWrapper.current.querySelector('svg #content');
      if (!svgElem) {
        return false;
      } else {
        svgElem.innerHTML += vector;
      }
    }
  };

  useEffect(() => {
    const fixSurveyMapView = () => {
      if (user) {
        let mapWrapper = document.querySelector('div.mapWrapper');
        mapWrapper.classList.add('externalLayer');
      }
    };

    async function embedSurveyMap() {
      return new Promise((resolve) => {
        if (mapSelector && svgWrapper.current) {
          //wrapper is ref to element in html - using useref
          svgWrapper.current.innerHTML = mapSelector; //svg code in map, in store
        }
        let deptLabels = Array.from(
          document.querySelectorAll('.adjacency-name'),
        );
        hideDeptNames(deptLabels);
        fixSurveyMapView();
        resolve();
      });
    }

    async function embedIcon() {
      return new Promise((resolve) => {
        addIconToMap(vestibuleVectors);
        resolve();
      });
    }

    async function addVectors() {
      await embedSurveyMap();
      await embedIcon();
      focusOnVestibule(storeId?.coordinates);
    }
    addVectors();
  }, [
    focusOnVestibule,
    mapSelector,
    vestibuleVectors,
    storeId?.coordinates,
    user,
  ]);

  useEffect(
    function callVectorFns() {
      if (storeId?.coordinates) {
        let vectors = createVestibuleVectors(storeId?.coordinates);
        setVestibuleVectors(vectors);
      }
    },
    [coordinates, storeId?.coordinates],
  );

  return (
    <>
      <div
        data-testid="surveyMap"
        className="mapWrapper"
        ref={svgWrapper}
      ></div>
    </>
  );
};
