import { useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { TrackPageView } from '../../utils/analyticsTracking';
import {
  Card,
  Grid,
  Layout,
  Heading,
  Breadcrumbs,
  Form,
} from '@enterprise-ui/canvas-ui-react';
import { Button } from 'ui-library';

export const SurveySearch = () => {
  const [store, setStore] = useState('T');
  TrackPageView('/lookup', 'lookupPage');

  const navigate = useNavigate();
  const handleSearch = () => {
    navigate(`/locationList/?q=${store}`);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { store: 'T' },
    mode: 'onBlur',
  }); // create form handlers and errors

  return (
    <>
      <Layout.Body data-testid="surveySearchPage" includeRail>
        <Card className="hc-pa-normal cardContainer">
          <Grid.Container>
            <Breadcrumbs className="breadcrumbSpacing">
              <Breadcrumbs.Item as={ReactLink} to="/">
                Home
              </Breadcrumbs.Item>
              <Breadcrumbs.Item as={ReactLink} to="/surveySearch">
                Survey Search
              </Breadcrumbs.Item>
            </Breadcrumbs>
            <Grid.Item xs={12}>
              <Heading size={3}> Survey Search </Heading>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Form
                id="survey_search"
                onSubmit={handleSubmit(handleSearch)}
                className="formPosition"
              >
                <Grid.Container direction="row" align="center">
                  <Grid.Item md={3} xs={12}>
                    <Form.Field
                      className="fieldPosition"
                      data-testid="survey_search_id"
                      id="text"
                      type="text"
                      label="Store number"
                      error={errors.store}
                      errorText={errors.store && errors.store.message}
                      placeholder="T9999"
                      maxLength="5"
                      required={true}
                      {...register('store', {
                        required: true,
                        pattern: {
                          value: /T[0-9]{4}/,
                          message:
                            'Invalid location ID! Please follow this format: T1234',
                        },
                        minlength: {
                          value: 5,
                          message:
                            'Error! Location ID needs to be 5 characters long',
                        },
                        onBlur: (e) => {
                          setStore(e.target.value);
                        },
                        onChange: (e) => {
                          setStore(e.target.value);
                        },
                      })}
                    />
                  </Grid.Item>
                  <Grid.Item md={2} xs={12}>
                    <Button label="Submit" type="submit" id="submit" />
                  </Grid.Item>
                </Grid.Container>
              </Form>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
