export const passVestibuleLocation = (x, y) => {
  //   let viewbox =
  //     '-6.436984167226325 -6.436984167226325 141.61365167897915 103.96636253190572'; // could change to viewbox from map
  return `
      <svg x="${x - 5}" y="${
        y - 4
      }" width="11" height="10" overflow="hidden" transform-origin="${x} ${y}" viewBox="0 0 500 343">
  <g>
     <g>
	<path style="fill:#CC0000;" d="M200,277.52c-65.72,0-119,16.22-119,36.24C81,333.78,134.28,350,200,350
		c65.72,0,119-16.22,119-36.24C319,293.75,265.72,277.52,200,277.52z M259.52,324.13c-15.84,4.82-36.97,7.48-59.52,7.48
		c-22.55,0-43.69-2.66-59.52-7.48c-16.25-4.95-22.01-10.75-22.01-14.22c0-3.47,5.76-9.27,22.01-14.22
		c15.84-4.82,36.97-7.48,59.52-7.48h0.01c22.54,0,43.68,2.66,59.51,7.48c16.24,4.95,22.01,10.75,22.01,14.22
		C281.53,313.38,275.77,319.18,259.52,324.13z"/>
	<path style="fill:#CC0000;" d="M200.37,50c-51.22,0-92.74,41.52-92.74,92.74c0,8.8,1.25,17.31,3.55,25.37
		c1.41,4.97,2.95,9.9,5.39,14.36c19.02,34.82,83.81,134.76,83.81,134.76s64.79-99.94,83.81-134.76c2.43-4.46,3.98-9.39,5.39-14.36
		c2.29-8.07,3.54-16.57,3.54-25.37C293.12,91.52,251.59,50,200.37,50z M200.37,183.58c-12.93,0-24.48-6.05-31.96-15.46
		c-5.55-6.97-8.87-15.79-8.87-25.37c0-22.52,18.32-40.84,40.84-40.84c22.52,0,40.84,18.32,40.84,40.84c0,9.58-3.33,18.4-8.87,25.37
		C224.85,177.53,213.3,183.58,200.37,183.58z"/>
</g>
  </g>
  </svg>
      `;
};

export const createVestibuleVectors = (coordinates) => {
  let vestibuleVectors = [];
  let { x, y } = coordinates;
  let newVector = passVestibuleLocation(x, y);
  vestibuleVectors.push(newVector);
  return vestibuleVectors;
};
