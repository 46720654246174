import { Card, Heading, Divider } from '@enterprise-ui/canvas-ui-react';
import './styles/cardStyles.scss';

export const VestibuleDetailsCard = ({ data }) => {
  return (
    <Card elevation={0} className="hc-mv-xl hc-pa-normal">
      <Heading size={2} className="customHeader">
        Survey Details{' '}
      </Heading>
      <div className="detailsWrapper">
        <Heading size={5} className="customTextPosition hc-pb-normal">
          Installation Details
        </Heading>
        <Divider></Divider>
        <p className="hc-mv-normal hc-ph-dense">
          <strong>Submitted at: </strong> {data?.submitted_at}
        </p>
        <p className="hc-mb-normal hc-ph-dense">
          <strong>Address: </strong> {data?.address}
        </p>
        <p className="hc-mb-normal hc-ph-dense">
          <strong>Location ID: </strong> {data?.location_id}
        </p>
        <p className="hc-mb-normal hc-ph-dense">
          <strong>Floor: </strong> {data?.floor_id}
        </p>
        <p className="hc-mb-normal hc-ph-dense">
          <strong>Technician Name: </strong> {data?.technician_name}
        </p>
        <p className="hc-mb-normal hc-ph-dense">
          <strong>Vendor Name: </strong> {data?.vendor_name}
        </p>
        <Heading size={5} className="customTextPosition hc-pv-normal ">
          Vestibule Details
        </Heading>
        <Divider className="hc-ph-expanded" />
        <p className="hc-mv-normal hc-ph-dense">
          <strong>Vestibule Name: </strong> {data?.vestibule_name}
        </p>
        <p className="hc-mb-normal hc-ph-dense">
          <strong>Vestibule Type: </strong>{' '}
          {data?.vestibule_details?.vestibule_type}
        </p>
        {!isNaN(data?.vestibule_details?.attachment_links) && (
          <p className="hc-mv-normal hc-ph-dense">
            <strong>Number of Images uploaded: </strong>{' '}
            {data?.vestibule_details?.attachment_links}
          </p>
        )}

        <p className="hc-mb-normal hc-ph-dense">
          <strong>Ceiling Height: </strong>{' '}
          {data?.vestibule_details.ceiling_height}
        </p>
        {data?.vestibule_details.entrance_door_count > 0 && (
          <>
            <p className="hc-mb-normal hc-ph-dense">
              <strong>Entrance Door Count: </strong>{' '}
              {data?.vestibule_details.entrance_door_count}
            </p>
            <p className="hc-mb-normal hc-ph-dense">
              <strong>Entrance Door Width: </strong>{' '}
              {data?.vestibule_details.entrance_door_width}
            </p>
            <p className="hc-mb-normal hc-ph-dense">
              <strong>Exit Door Count: </strong>{' '}
              {data?.vestibule_details.exit_door_count}
            </p>
            <p className="hc-mb-normal hc-ph-dense">
              <strong>Exit Door Width: </strong>{' '}
              {data?.vestibule_details.exit_door_width}
            </p>
          </>
        )}
        <p className="hc-mb-normal hc-ph-dense hc-pb-dense">
          <strong>Can this be installed?: </strong>{' '}
          {data?.vestibule_details.user_can_install}
        </p>
        {data?.vestibule_details?.additional_notes && (
          <p className="hc-mb-normal hc-ph-dense hc-pb-dense">
            <strong>Additional Notes: </strong>{' '}
            {data?.vestibule_details?.additional_notes}
          </p>
        )}
        {data?.vestibule_details?.neptune_x && (
          <>
            <Heading size={5} className="customTextPosition hc-pb-normal">
              Coordinates
            </Heading>
            <Divider className="hc-ph-expanded" />
            <p className="hc-mv-normal hc-ph-dense hc-pb-dense">
              <strong>Neptune-X-Coordinates: </strong>{' '}
              {data?.vestibule_details.neptune_x}
            </p>
            <p className="hc-mb-normal hc-ph-dense hc-pb-dense">
              <strong>Neptune-Y-Coordinates: </strong>{' '}
              {data?.vestibule_details.neptune_y}
            </p>
          </>
        )}
      </div>
    </Card>
  );
};
