import {
  Card,
  Grid,
  Layout,
  Heading,
  Breadcrumbs,
  Spinner,
} from '@enterprise-ui/canvas-ui-react';
import { useAuth } from '@praxis/component-auth';
import { getExternalToken } from '../../utils/authHelpers';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';
import { useLocation, useNavigate, Link as ReactLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button } from 'ui-library';
import './styles/styles.scss';
import { VestibuleDetailsCard } from '../../components/VestibuleDetailsCard';
import {
  submitNewForm,
  submitNewFormExt,
  getFormSubmissionDetails,
} from '../../app/presurvey/formSlice';

export const SurverySuccessPage = () => {
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();
  const [token, setToken] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [sessionSubmitted, setSessionSubmitted] = useState(false);

  let formData = location?.state?.formData;
  const vestibuleLabel = location?.state?.vestibuleLabel;
  const totalReaders = location?.state?.totalReaders;
  const response = useSelector((state) => state.vestibuleForm);
  const formSubmission = useSelector(getFormSubmissionDetails);
  let verifyLabel = sessionStorage
    ?.getItem('SurveysCompleted')
    ?.includes(vestibuleLabel);

  const data = {
    submitted_at: formSubmission?.submitted_at,
    address: formSubmission?.address,
    location_id: formSubmission?.location_id,
    floor_id: formSubmission?.floor_id,
    vestibule_name: formSubmission?.vestibule_name,
    technician_name: formSubmission?.technician_name,
    vendor_name: formSubmission?.vendor_name,
    vestibule_details: {
      vestibule_type: formSubmission?.vestibule_details?.vestibule_type,
      ceiling_height: formSubmission?.vestibule_details?.ceiling_height,
      entrance_door_count:
        formSubmission?.vestibule_details?.entrance_door_count,
      entrance_door_width:
        formSubmission?.vestibule_details?.entrance_door_width,
      exit_door_count: formSubmission?.vestibule_details?.exit_door_count,
      exit_door_width: formSubmission?.vestibule_details?.exit_door_width,
      user_can_install: formSubmission?.vestibule_details?.user_can_install,
      attachment_links:
        formSubmission?.vestibule_details?.attachment_links?.length,
      additional_notes: formSubmission?.vestibule_details?.additional_notes,
    },
  };

  function updateLocalStorage() {
    if (
      !response.error &&
      !response.loading &&
      response.error == null &&
      !verifyLabel //Added verification that form was actually sent
    ) {
      //triggers conditional success function on the Vestibule Page
      let existingSurveys = [];
      if (JSON.parse(sessionStorage.getItem('SurveysCompleted'))?.length > 0) {
        existingSurveys = JSON.parse(
          sessionStorage.getItem('SurveysCompleted'),
        );
        existingSurveys?.push(vestibuleLabel);
        sessionStorage['SurveysCompleted'] = JSON.stringify(existingSurveys);
      } else {
        existingSurveys?.push(vestibuleLabel);
        sessionStorage['SurveysCompleted'] = JSON.stringify(existingSurveys);
      }
    }
  }

  useEffect(() => {
    setToken(getExternalToken(user));
  }, [user]);

  useEffect(() => {
    if (session) {
      dispatch(submitNewForm(formData));
    } else if (user) {
      dispatch(submitNewFormExt({ formData, token }));
    }
  }, [dispatch, formData, session, token, user]);

  updateLocalStorage();
  return (
    <>
      <Layout.Body data-testid="surveySuccessPage" includeRail>
        <Card className="hc-pa-normal">
          <Grid.Container>
            <Breadcrumbs className="breadcrumbSpacing">
              <Breadcrumbs.Item as={ReactLink} to="/">
                Home
              </Breadcrumbs.Item>
              <Breadcrumbs.Item as={ReactLink} to="/survey">
                Pre-Installation
              </Breadcrumbs.Item>
            </Breadcrumbs>
            <Grid.Container align="center" justify="center">
              {!response.error && !response.loading && (
                <Grid.Item className="dividerMargin">
                  <Heading> Success! </Heading>
                </Grid.Item>
              )}
              {response.error && !response.loading && (
                <Grid.Item className="dividerMargin">
                  <Heading> Your submission failed. </Heading>
                  <p>Please try again.</p>
                  <strong>{response.error}</strong>
                </Grid.Item>
              )}
            </Grid.Container>
            <Grid.Container align="center" justify="center">
              {!response.loading && !response.error && (
                <>
                  <Grid.Item xs={11}>
                    <Card>
                      <Grid.Item></Grid.Item>
                      <VestibuleDetailsCard data={data} />
                    </Card>
                  </Grid.Item>
                </>
              )}
              {response.loading && (
                <Grid.Item>
                  <Spinner className="dividerMargin" />
                </Grid.Item>
              )}
            </Grid.Container>
          </Grid.Container>
          <Grid.Container align="center" justify="center">
            {formData && !response.error && !response.loading && (
              <Grid.Item className="dividerMargin">
                <Button
                  label="Return to Vestibules"
                  type="secondary"
                  name="next_button"
                  id="next_vestibule"
                  onClick={() => {
                    navigate(
                      `/survey-reader-list/?q=${formData?.location_id}`,
                      { state: { formData } },
                    );
                  }}
                />
              </Grid.Item>
            )}
            {response.error && !response.loading && (
              <Grid.Item className="dividerMargin">
                <Button
                  label="Retry"
                  type="primary"
                  name="retry_button"
                  id="retry_vestibule"
                  onClick={() => {
                    navigate(
                      `/survey-reader-list/?q=${formData?.location_id}`,
                      {
                        state: {
                          formData,
                        },
                      },
                    );
                  }}
                />
              </Grid.Item>
            )}
            {!response.loading &&
              totalReaders ===
                JSON.parse(sessionStorage.getItem('SurveysCompleted'))
                  ?.length && (
                <Grid.Item className="dividerMargin">
                  <Button
                    label="Submit Session"
                    type="primary"
                    name="home_button"
                    id="home_exit"
                    onClick={() => {
                      setSessionSubmitted(true);
                      setTimeout(() => {
                        navigate(`/`, sessionStorage.clear());
                      }, 5000);
                    }}
                  />
                </Grid.Item>
              )}
            {sessionSubmitted && (
              <>
                <Grid.Item
                  xs={12}
                  className="hc-mt-none hc-pt-none hc-pb-md hc-ta-center"
                >
                  <p className="hc-clr-success hc-ma-none hc-pa-none">
                    Your survey has been submitted! You will now be redirected
                    to the home page. Thank you!{' '}
                  </p>
                </Grid.Item>
              </>
            )}
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
