import {
  Card,
  Grid,
  Layout,
  Breadcrumbs,
  Heading,
  Form,
  Input,
  Spinner,
  Anchor,
} from '@enterprise-ui/canvas-ui-react';
import FileResizer from 'react-image-file-resizer';
import { getMap, getMapExt } from '../../app/neptune/neptuneSlice';
import { Link as ReactLink } from 'react-router-dom';
import { CreatableSelect } from '../../components/CreatableSelect';
import { Select } from '../../components/Select';
import { Button } from 'ui-library';
import { dataURIToBlob } from '../../utils/helpers';
import EnterpriseIcon, {
  ErrorFilledIcon,
  CheckCircleFilledIcon,
} from '@enterprise-ui/icons';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { SurveyMap } from '../../components/SurveyMap';
import './styles/styles.scss';

import { getExternalToken } from '../../utils/authHelpers';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';
import Label from '@enterprise-ui/canvas-ui-react/components/Input/Label';

export const VestibuleTemplate = () => {
  /* Auth related constants */
  const { user } = useInstallerAuthContext();
  const [token, setToken] = useState('');
  const dispatch = useDispatch();
  /* Navigation related constants */
  const location = useLocation()?.state;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const vestibule_number = searchParams.get('vestibule');
  const floor_number = searchParams.get('floor');
  const [canUserInstall, setCanUserInstall] = useState(false);
  const [didPhotosUpload, setDidPhotosUpload] = useState(false);
  const [invalidType, setInvalidType] = useState(false);
  /*error handling state*/
  const [typeSubmitted, setTypeSubmitted] = useState();
  const [installSubmitted, setInstallSubmitted] = useState();
  const [reasonSubmitted, setReasonSubmitted] = useState();
  /* Location State related data*/
  const installerData = location?.formData;
  const vestibuleValue = location?.vestibuleValue;
  const vestibuleLabel = location?.vestibuleLabel;
  const coordinates = location?.coordinates;
  const totalReaders = location?.totalReaders;
  const store = installerData?.location_id;
  const [photoObj, setPhotoObj] = useState('');
  const photoArr = Object.values(photoObj);
  const [formData, setFormData] = useState(installerData);
  const { loading, error } = useSelector((state) => state.neptune);

  const handleSubmit = () => {
    //Unable to submit unless required fields filled
    formData?.vestibule_type === undefined
      ? setTypeSubmitted(true)
      : photoArr.length < 2
        ? setDidPhotosUpload(true)
        : formData?.user_can_install === undefined
          ? setInstallSubmitted(true)
          : formData?.user_can_install.length < 3
            ? setReasonSubmitted(true)
            : navigate(`/survey-success`, {
                state: {
                  formData,
                  vestibuleValue,
                  vestibuleLabel,
                  totalReaders,
                },
              });
  };

  const handlePhotoForm = () => {
    formData.file1 = photoArr[0];
    formData.file2 = photoArr[1];
    if (photoArr?.length > 2) {
      for (let i = 3; i <= photoArr?.length; i++) {
        let newFile = photoArr[i - 1];
        formData['file' + i] = newFile;
      }
    }
  };

  function pushPhotoIntoArray(event) {
    let eventNum = event?.target?.files;
    let totalLength = Object?.keys(photoObj)?.length;
    Object?.entries(eventNum)?.forEach(function (key) {
      if (key[1]?.size > 5 * 1000 * 1000) {
        FileResizer?.imageFileResizer(
          key[1],
          2000, // maxWidth
          2000, // maxHeight
          'JPEG', // compressFormat
          100, // quality
          0, // rotation
          (uri) => {
            let compressedFile = dataURIToBlob(uri);
            if (totalLength < 9) {
              setPhotoObj({
                ...photoObj,
                [totalLength + 1]: compressedFile,
              });
            }
          },
          'base64', // outputType
        );
      } else {
        if (totalLength < 9) {
          setPhotoObj({
            ...photoObj,
            [totalLength + 1]: key[1],
          });
        }
      }
    });
  }

  useEffect(() => {
    setToken(getExternalToken(user));
  }, [user]);

  useEffect(
    function dispatchMapRequest() {
      if (store) {
        let storeId = store.substr(1);
        let floorNum = floor_number;
        if (user && token) {
          dispatch(getMapExt({ storeId, floorNum, token }));
        } else {
          dispatch(getMap({ storeId, floorNum }));
        }
      }
    },
    [dispatch, store, floor_number, user, token],
  );

  return (
    <>
      <Layout.Body data-testid="vestibuleFormPage" includeRail>
        <Card className="hc-pa-normal">
          <Grid.Container>
            <Breadcrumbs className="breadcrumbSpacing">
              <Breadcrumbs.Item as={ReactLink} to="/">
                Home
              </Breadcrumbs.Item>
              <Breadcrumbs.Item as={ReactLink} to="/survey">
                Pre-Installation
              </Breadcrumbs.Item>
            </Breadcrumbs>

            <Grid.Item xs={12}>
              {vestibule_number !== 'e' ? (
                <Heading size={3}>
                  {' '}
                  Vestibule {vestibule_number} - Floor {floor_number}{' '}
                </Heading>
              ) : (
                <Heading size={3}> TM Entrance - Floor {floor_number} </Heading>
              )}
            </Grid.Item>
            {error && (
              <Grid.Item xs={12}>
                <Card elevation={0}>
                  <Input.Info error>Map is currently unavailable.</Input.Info>
                </Card>
              </Grid.Item>
            )}
            {!error && !loading && coordinates && (
              <Grid.Item xs={12}>
                <SurveyMap
                  storeId={store}
                  floorNum={floor_number}
                  coordinates={coordinates}
                />
              </Grid.Item>
            )}
            {loading && !error && <Spinner />}
            {installerData && store ? (
              <Form
                role="form"
                id="registration_form"
                className="hc-mt-md"
                onSubmit={(e) => {
                  e.preventDefault();
                  handlePhotoForm();
                  handleSubmit();
                }}
              >
                <Grid.Container className="hc-pa-normal" align="center">
                  <Grid.Item xs={12}>
                    <p className="hc-fs-lg">
                      <strong>Vestibule Name:</strong>{' '}
                      {formData?.vestibule_name?.split('_')?.[0]}
                    </p>
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Select
                      data-testid="vestibule_type"
                      id="vestibule_type"
                      label="Type of Vestibule*"
                      name="vestibuleType"
                      classNamePrefix="customSelect"
                      options={[
                        {
                          value: 'Type A',
                          label: 'Type A',
                        },
                        {
                          value: 'Type B',
                          label: 'Type B',
                        },
                        {
                          value: 'Type C',
                          label: 'Type C',
                        },
                        {
                          value: 'Type D',
                          label: 'Type D',
                        },
                        {
                          value: 'Type E',
                          label: 'Type E',
                        },
                        {
                          value: 'Type F',
                          label: 'Type F',
                        },
                      ]}
                      onChange={(e) => {
                        setTypeSubmitted(false);
                        setFormData({
                          ...formData,
                          vestibule_type: e?.value,
                          floor_id: floor_number,
                        });
                      }}
                    />
                    {typeSubmitted && (
                      <p className="hc-fs-xs hc-clr-error hc-lh-dense">
                        <EnterpriseIcon icon={ErrorFilledIcon} size="inline" />{' '}
                        Please select a vestibule type!
                      </p>
                    )}
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Form.Field
                      data-testid="entrance_door_num"
                      type="radio"
                      id="entrance_door_num"
                      label="Number of Doors in Entrance Lane"
                      name="Number of Doors in Entrance Lane"
                      options={[
                        {
                          value: '1', //Placeholder!
                          label: '1',
                          disabled: false,
                        },
                        {
                          value: '2',
                          label: '2',
                          disabled: false,
                        },
                        {
                          value: '3',
                          label: '3',
                          disabled: false,
                        },
                        {
                          value: '4',
                          label: '4',
                          disabled: false,
                        },
                      ]}
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          entrance_door_count: Number(e?.target?.value),
                        });
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Form.Field
                      data-testid="exit_door_num"
                      id="exit_door_num"
                      type="radio"
                      label="Number of Doors in Exit Lane"
                      name="Number of Doors in Exit Lane"
                      options={[
                        {
                          value: '1', //Placeholder!
                          label: '1',
                          disabled: false,
                        },
                        {
                          value: '2',
                          label: '2',
                          disabled: false,
                        },
                        {
                          value: '3',
                          label: '3',
                          disabled: false,
                        },
                        {
                          value: '4',
                          label: '4',
                          disabled: false,
                        },
                      ]}
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          exit_door_count: Number(e?.target?.value),
                        });
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={10}>
                    <Form.Field
                      data-testid="ceiling_height"
                      type="number"
                      label="Height of the Ceiling:"
                      id="ceiling_height"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ceiling_height: parseFloat(e?.target?.value),
                        });
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <p className="text"> ft</p>
                  </Grid.Item>
                  <Grid.Item xs={10}>
                    <Form.Field
                      data-testid="entrance_width"
                      type="number"
                      min="1"
                      max="50"
                      label="Width of the Entrance Lane:"
                      id="entrance_lane_width"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          entrance_door_width: parseFloat(e?.target?.value),
                        });
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <p className="text"> ft</p>
                  </Grid.Item>
                  <Grid.Item xs={10}>
                    <Form.Field
                      data-testid="exit_width"
                      type="number"
                      min="1"
                      max="50"
                      label="Width of the Exit Lane:"
                      id="exit_lane_width"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          exit_door_width: parseFloat(e?.target?.value),
                        });
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <p className="text"> ft</p>
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Select
                      data-testid="can_install"
                      id="can_install"
                      label="Can you install? (Y/N, Select from Multiple Issues)*"
                      name="Vestibule Name"
                      classNamePrefix="customSelect"
                      options={[
                        {
                          value: 'Yes', //Placeholder!
                          label: 'Yes',
                          disabled: false,
                        },
                        {
                          value: 'No',
                          label: 'No',
                          disabled: false,
                        },
                      ]}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          user_can_install: e?.value,
                        });
                        setInstallSubmitted(false);
                        if (e?.value === 'No') {
                          setCanUserInstall(true);
                        } else {
                          setCanUserInstall(false);
                        }
                      }}
                      createLabel="Add new issue for installation"
                    />
                    {installSubmitted && (
                      <p className="hc-fs-xs hc-clr-error hc-lh-dense">
                        <EnterpriseIcon icon={ErrorFilledIcon} size="inline" />{' '}
                        Please enter or select whether or not you can install!
                      </p>
                    )}
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    {canUserInstall && (
                      <>
                        <CreatableSelect
                          data-testid="can_install_other_details"
                          id="other_details"
                          type="textarea"
                          label="Please select or type reason for being unable to install.*"
                          options={[
                            {
                              value: '...Inaccessible Hard Lid Ceiling', //Placeholder!
                              label: 'Inaccessible Hard Lid Ceiling',
                            },
                            {
                              value: '...HVAC Unit Obstruction',
                              label: 'HVAC Unit Obstruction',
                            },
                            {
                              value: '...Lighting Fixture Obstruction',
                              label: 'Lighting Fixture Obstruction',
                            },
                            {
                              value: '...Sprinkler Head Interference',
                              label: 'Sprinkler Head Interference',
                            },
                            {
                              value: '...I-Beam or Red Iron Beam Obstruction',
                              label: 'I-Beam or Red Iron Beam Obstruction',
                            },
                            {
                              value: '...Glass Front Barrier',
                              label: 'Glass Front Barrier',
                            },
                            {
                              value: '...Ceiling Height Restriction',
                              label: 'Ceiling Height Restriction',
                            },
                            {
                              value: '...Low Soffit Depth',
                              label: 'Low Soffit Depth',
                            },
                            {
                              value: '...Vent Blockage',
                              label: 'Vent Blockage',
                            },
                            {
                              value: '...No Crawl Space Access',
                              label: 'No Crawl Space Access',
                            },
                            {
                              value: '...Exit Sign Blockage',
                              label: 'Exit Sign Blockage',
                            },
                          ]}
                          onChange={(e) => {
                            setReasonSubmitted(false);
                            setFormData({
                              ...formData,
                              user_can_install: 'No' + e?.value,
                            });
                          }}
                          createLabel="Add new issue for installation"
                        />
                        {reasonSubmitted && (
                          <p className="hc-fs-xs hc-clr-error hc-lh-dense">
                            <EnterpriseIcon
                              icon={ErrorFilledIcon}
                              size="inline"
                            />{' '}
                            Please enter or select a reason!{' '}
                          </p>
                        )}
                      </>
                    )}
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Form.Field
                      data-testid="other_details"
                      id="more_details"
                      type="textarea"
                      label="Other details if applicable"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          additional_notes: e?.target?.value || 'None',
                        });
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    {photoArr.map((photo) => {
                      return (
                        <Card className="hc-pa-normal && cardMargin & hc-bg-success">
                          <Heading className="hc-clr-contrast-weak">
                            <EnterpriseIcon
                              icon={CheckCircleFilledIcon}
                              size="inline"
                            />{' '}
                            Submitted!
                          </Heading>{' '}
                          <p className="hc-clr-contrast-weak">
                            {photo.name} has been submitted!{' '}
                          </p>
                        </Card>
                      );
                    })}
                    {didPhotosUpload && (
                      <Card className="hc-pa-normal && cardMargin && hc-bg-error && hc-clr-contrast-weak">
                        <EnterpriseIcon icon={ErrorFilledIcon} size="inline" />{' '}
                        Error: Select <strong>two</strong> photos of the
                        vestibule.{' '}
                      </Card>
                    )}
                    {invalidType && (
                      <Card className="hc-pa-normal && cardMargin && hc-bg-error && hc-clr-contrast-weak">
                        <EnterpriseIcon icon={ErrorFilledIcon} size="inline" />{' '}
                        Error: <strong>Invalid File Type.</strong> Please only
                        upload <strong>jpeg/jpg or png.</strong>{' '}
                      </Card>
                    )}
                    <Label>
                      Please upload atleast <strong>two</strong> photos of
                      vestibule:
                    </Label>
                    <Input.DropArea
                      data-testid="photo_area"
                      id="photo_area"
                      size="expanded"
                      dropText="Upload each photo here"
                      onUpdate={(event) => {
                        if (
                          event?.target.files[0]?.type ===
                            ('image/jpeg' || 'image/jpg') ||
                          event?.target.files[0]?.type === 'image/png' ||
                          event?.target.files[0]?.type === 'image/heic' //Would NOT take png files unless written like this
                        ) {
                          pushPhotoIntoArray(event);
                          setDidPhotosUpload(false);
                          setInvalidType(false);
                        } else {
                          setInvalidType(true);
                        }
                      }}
                      clickOnly
                      fullwidth
                      instructionText="Click to upload vestibule photos here"
                    />
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container align="center" justify="center">
                  <Grid.Item>
                    <Button
                      className="dividerMargin"
                      label="Prev"
                      type="secondary"
                      name="prev_button"
                      id="prev_button"
                      onClick={() => {
                        navigate(`/survey-reader-list/?q=${store}`, {
                          state: { formData },
                        });
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      className="dividerMargin"
                      label="Continue"
                      type="submit"
                      name="next_button"
                      id="submit"
                    />
                  </Grid.Item>
                </Grid.Container>
              </Form>
            ) : (
              <Card elevation={0} className="hc-pa-normal cardContainer">
                <Input.Info error>
                  There was an issue loading the details for this location.
                  Please make sure{' '}
                  <Anchor to="/survey" as={ReactLink} className="iot-link">
                    previous information{' '}
                  </Anchor>{' '}
                  was submitted correctly.
                </Input.Info>
              </Card>
            )}
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
