import {
  createSlice,
  createAsyncThunk,
  isAnyOf,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const PATH = API_ROUTES.vestibuleLocation;
const API_KEY = apiConfig.apiKey;

// Set up initial state
const initialState = {
  loading: false,
  surveyLocation: [],
  error: null,
};

export const vestibulesByStore = createAsyncThunk(
  'survery/getSurvery',
  async (store) => {
    const response = await axios.get(`${RYDER_HOST}${PATH}${store}`, {
      headers: {
        // headers
        'x-api-key': API_KEY, // 'x-api
      },
    });
    return response.data;
  },
);

export const vestibulesByStoreExt = createAsyncThunk(
  'survery/getSurveryExt',
  async (location) => {
    const { store, token } = location;
    const response = await axios.get(`${EXTERNAL_HOST}${PATH}${store}`, {
      headers: {
        // headers
        'x-api-key': API_KEY, // 'x-api
        Authorization: token,
      },
    });
    return response.data;
  },
);

// Create slice logic
export const surveySlice = createSlice({
  name: 'vestibuleLocation',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(
        isAnyOf(vestibulesByStore.fulfilled, vestibulesByStoreExt.fulfilled),
        (state, action) => {
          state.loading = false;
          state.error = null;
          state.surveyLocation = action.payload;
        },
      )
      .addMatcher(
        isAnyOf(vestibulesByStore.pending, vestibulesByStoreExt.pending),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(vestibulesByStore.rejected, vestibulesByStoreExt.rejected),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});

// Actions are generated for each reducer function
export default surveySlice.reducer;

export const getDeviceStore = (state) => state.vestibuleLocation.surveyLocation;

export const getVestibulesDetails = createSelector(
  getDeviceStore,
  (deviceByStore) => {
    return deviceByStore?.vestibules;
  },
);

export const getAddressDetails = createSelector(
  getDeviceStore,
  (deviceByStore) => {
    return deviceByStore?.address;
  },
);
