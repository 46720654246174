import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const PATH = API_ROUTES.surveyResults;
const API_KEY = apiConfig.apiKey;

const initialState = {
  submission: {},
  loading: false,
  error: null,
};

export const submitSurveyAction = createAsyncThunk(
  'survey/submitSurveyAction',
  async (payload) => {
    const response = await axios.put(
      `${RYDER_HOST}${PATH}`,
      {
        ...payload,
      },
      {
        headers: {
          'x-api-key': API_KEY,
        },
      },
    );
    return response.data;
  },
);

export const submitSurveyActionExt = createAsyncThunk(
  'survey/submitSurveyAction',
  async (payloadExt) => {
    const { payload, token } = payloadExt;
    const response = await axios.put(
      `${EXTERNAL_HOST}${PATH}`,
      {
        ...payload,
      },
      {
        headers: {
          'x-api-key': API_KEY,
          Authorization: token,
        },
      },
    );
    return response.data;
  },
);

export const surveyActionSlice = createSlice({
  name: 'surveyByAction',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(
        isAnyOf(submitSurveyAction.fulfilled, submitSurveyActionExt.fulfilled),

        (state, action) => {
          state.loading = false;
          state.error = null;
          state.submission = action.payload;
        },
      )
      .addMatcher(
        isAnyOf(submitSurveyAction.pending, submitSurveyActionExt.pending),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(submitSurveyAction.rejected, submitSurveyActionExt.rejected),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});

export default surveyActionSlice.reducer;

// Selector fns
export const getSubmitForm = (state) => state.surveyByAction.submission;

export const getSurveyActionResponse = createSelector(
  getSubmitForm,
  (formSubmission) => {
    console.log(formSubmission);
    return formSubmission;
  },
);
