import {
  createSlice,
  createAsyncThunk,
  isAnyOf,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const EXTERNAL_HOST = apiConfig.externalNeptuneHost;
const PATH = API_ROUTES.mapByLocation;
const API_KEY = apiConfig.apiKey;

const initialState = {
  activeReader: '',
  storeFloor: '',
  svgMaps: {},
  loading: false,
  error: null,
};

export const getMap = createAsyncThunk('neptune/getMap', async (payload) => {
  const { storeId, floorNum } = payload;
  const mapFloorLayers = `floor_id=${floorNum}&layers=aisle-shapes,adjacency-names,cart-storage-shapes,clinic-shapes,fitting-room-shapes,optical-shapes,pharmacy-shapes,register-shapes,sales-floor-island-shapes,wall-shapes&css_name=basic&key=`;

  const response = await axios.get(
    `${EXTERNAL_HOST}/external${PATH}${storeId}&${mapFloorLayers}${API_KEY}`,
  );
  return response.data;
});

export const getMapExt = createAsyncThunk(
  'neptune/getMapExt',
  async (payload) => {
    const { storeId, floorNum, token } = payload;
    const externalFloorLayers = `floor_id=${floorNum}&layers=aisle-shapes,adjacency-names,cart-storage-shapes,clinic-shapes,fitting-room-shapes,optical-shapes,pharmacy-shapes,register-shapes,sales-floor-island-shapes,wall-shapes&css_name=basic&key=`;

    const response = await axios.get(
      `${EXTERNAL_HOST}/external${PATH}${storeId}&${externalFloorLayers}${API_KEY}`,
      {
        headers: {
          Authorization: token,
        },
      },
    );
    return response.data;
  },
);

export const neptuneSlice = createSlice({
  name: 'neptune',
  initialState,
  reducers: {
    setActiveReader: (state, action) => {
      state.activeReader = action.payload;
    },
    setStoreFloor: (state, action) => {
      state.storeFloor = action.payload;
    },
    setMapPlaceholder: (state, action) => {
      let { storeId, floorNum } = action.meta.arg;
      state.svgMaps[`T${storeId}-${floorNum}`] = 'Unavailable in Neptune';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMap.fulfilled, (state, action) => {
        let { storeId, floorNum } = action.meta.arg;
        state.loading = false;
        state.error = null;
        state.svgMaps[`T${storeId}-${floorNum}`] = action.payload;
      })
      .addCase(getMapExt.fulfilled, (state, action) => {
        let { storeId, floorNum } = action.meta.arg;
        state.loading = false;
        state.error = null;
        state.svgMaps[`T${storeId}-${floorNum}`] = action.payload;
      })
      .addMatcher(isAnyOf(getMap.pending, getMapExt.pending), (state) => {
        state.loading = true;
        state.error = false;
      })
      .addMatcher(
        isAnyOf(getMap.rejected, getMapExt.rejected),
        (state, action) => {
          let { storeId, floorNum } = action.meta.arg;
          state.loading = false;
          state.error = action.error.message;
          state.svgMaps[`T${storeId}-${floorNum}`] = 'Unavailable in Neptune';
        },
      );
  },
});

export const { setActiveReader, setStoreFloor, setMapPlaceholder } =
  neptuneSlice.actions;
export default neptuneSlice.reducer;

/* Selectors */

const storeMaps = (state) => state.neptune.svgMaps;

const getStore = (_, storeId) => storeId;

const getFloor = (_, storedId, floor) => floor;

export const selectMapByStore = createSelector(
  storeMaps,
  getStore,
  getFloor,
  (maps, storeId, floor) => {
    return maps && maps[`${storeId}-${floor}`];
  },
);
