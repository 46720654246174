import {
  Card,
  Grid,
  Layout,
  InputRadio,
  Breadcrumbs,
  Heading,
  Input,
  Anchor,
  Spinner,
} from '@enterprise-ui/canvas-ui-react';
import {
  vestibulesByStore,
  vestibulesByStoreExt,
  getVestibulesDetails,
  getAddressDetails,
} from '../../app/presurvey/surveySlice';
import { useAuth } from '@praxis/component-auth';
import { getExternalToken } from '../../utils/authHelpers';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';
import { Link as ReactLink } from 'react-router-dom';
import { Button } from 'ui-library';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './styles/styles.scss';
import { convertTwoArrayiIntoOne } from '../../utils/helpers';

export const ReaderList = () => {
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();
  const [token, setToken] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const reader = useSelector(getVestibulesDetails);
  const address = useSelector(getAddressDetails);
  let installerData = location?.state?.formData;
  const [searchParams] = useSearchParams();
  const store = searchParams?.get('q');
  let [vestibuleValue, setVestibuleValue] = useState();
  let [vestibuleLabel, setVestibuleLabel] = useState();
  let [sessionSubmitted, setSessionSubmitted] = useState(false);
  const readerState = convertTwoArrayiIntoOne(reader);
  const [formData, setFormData] = useState(installerData);
  const { loading, error } = useSelector((state) => state?.vestibuleLocation);

  useEffect(() => {
    if (store !== null) {
      if (session) {
        dispatch(vestibulesByStore(store));
      } else if (user) {
        dispatch(vestibulesByStoreExt({ store, token }));
      }
    }
  }, [dispatch, session, store, token, user]);

  useEffect(() => {
    setToken(getExternalToken(user));
  }, [user]);

  // Load completed surveys from sessionStorage and add classes after render
  useEffect(() => {
    const surveysCompleted = new Set(
      JSON?.parse(sessionStorage?.getItem('SurveysCompleted')) || [],
    );

    if (readerState?.length > 0) {
      // Use setTimeout to ensure DOM updates after rendering
      setTimeout(() => {
        document
          ?.querySelectorAll('div.C-InputRadio label')
          ?.forEach((label) => {
            const labelText = label.textContent;
            if (surveysCompleted?.has(labelText)) {
              label?.classList?.add('vestibule-list-elem');
            }
          });
      }, 0);
    }
  }, [readerState]);

  function clearPhotoFromObj() {
    [
      'file1',
      'file2',
      'file3',
      'file4',
      'file5',
      'file6',
      'file7',
      'file8',
      'file9',
      'file10',
      'vestibule_type',
      'user_can_install',
      'ceiling_height',
      'user_can_install',
      'vestibule_type',
      'floor_id',
      'entrance_door_count',
      'exit_door_count',
      'entrance_door_width',
      'exit_door_width',
    ]?.forEach((e) => delete formData[e]);
  }

  clearPhotoFromObj(); //Clears photos from formData while keeping important info
  return (
    <>
      <Layout.Body data-testid="homePage" includeRail>
        <Card className="hc-pa-normal">
          <Grid.Container>
            <Breadcrumbs className="breadcrumbSpacing">
              <Breadcrumbs.Item as={ReactLink} to="/">
                Home
              </Breadcrumbs.Item>
              <Breadcrumbs.Item as={ReactLink} to="/survey">
                Pre-Installation
              </Breadcrumbs.Item>
            </Breadcrumbs>
            <Grid.Item xs={12}>
              <Heading size={3}> Installer Information</Heading>
            </Grid.Item>
            {reader && !error && !loading && installerData && (
              <Grid.Item xs={12}>
                <p> Select a reader to start filling out the form:</p>
                <InputRadio
                  id="vestibule_store" //Wanna compare saved array options I have, any option that matches append a icon or something
                  options={readerState}
                  required
                  onChange={(e) => {
                    setVestibuleValue(e?.target?.value?.split('_')?.[0]);
                    setVestibuleLabel(e?.target?.labels[0]?.textContent);
                    setFormData({
                      ...formData,
                      vestibule_name: e?.target?.value
                        ?.split('_')?.[0]
                        ?.slice(1),
                      address: address,
                      neptune_x:
                        Number(e?.target?.value?.split('_')?.[1]) * 1.0,
                      neptune_y:
                        Number(e?.target?.value?.split('_')?.[2]) * 1.0,
                    });
                  }}
                />
              </Grid.Item>
            )}
            {(error && !loading) ||
              (readerState === 'undefined' && !loading && (
                <Card elevation={0} className="hc-pa-normal cardContainer">
                  <Input.Info error>
                    There was an issue loading the details for this location.
                    Please make sure{' '}
                    <Anchor to="/survey" as={ReactLink} className="iot-link">
                      previous information{' '}
                    </Anchor>{' '}
                    was submitted correctly.
                  </Input.Info>
                </Card>
              ))}
            {loading && !error && (
              <Grid.Item xs={12}>
                <Spinner className="spinnerSize" />
              </Grid.Item>
            )}
          </Grid.Container>
          <Grid.Container align="center" justify="center">
            <Grid.Item className="dividerMargin">
              <Button
                label="Prev"
                type="primary"
                name="prev_button"
                id="prev_button"
                onClick={() => {
                  navigate(
                    `/survey`,
                    {
                      state: { formData },
                    },
                    sessionStorage.clear(),
                  );
                }}
              />
            </Grid.Item>
            {vestibuleValue && (
              <Grid.Item className="dividerMargin">
                <Button
                  label="Next"
                  type="submit"
                  name="next_button"
                  id="submit"
                  onClick={() => {
                    if (vestibuleLabel?.includes('TM Entrance')) {
                      navigate(
                        `/survey-teamMember/?TM=${vestibuleValue?.slice(-1)}&floor=${vestibuleValue?.slice(0, 1)}`,
                        {
                          state: {
                            formData,
                            vestibuleValue,
                            vestibuleLabel,
                            coordinates: {
                              x: formData?.neptune_x,
                              y: formData?.neptune_y,
                            },
                            totalReaders: readerState?.length,
                          },
                        },
                      );
                    } else {
                      navigate(
                        `/survey-vestibule/?vestibule=${vestibuleValue?.slice(-1)}&floor=${vestibuleValue?.slice(0, 1)}`,
                        {
                          state: {
                            formData,
                            vestibuleValue,
                            vestibuleLabel,
                            coordinates: {
                              x: formData?.neptune_x,
                              y: formData?.neptune_y,
                            },
                            totalReaders: readerState?.length,
                          },
                        },
                      );
                    }
                  }}
                />
              </Grid.Item>
            )}
            {readerState?.length ===
              JSON.parse(sessionStorage?.getItem('SurveysCompleted'))
                ?.length && (
              <>
                <Grid.Item className="dividerMargin">
                  <Button
                    label="Submit Session"
                    type="secondary"
                    name="home_button"
                    id="home_exit"
                    onClick={() => {
                      setSessionSubmitted(true);
                      setTimeout(() => {
                        navigate(`/`, sessionStorage.clear());
                      }, 5000);
                    }}
                  />
                </Grid.Item>
              </>
            )}
            {sessionSubmitted && (
              <>
                <Grid.Item
                  xs={12}
                  className="hc-mt-none hc-pt-none hc-pb-md hc-ta-center"
                >
                  <p className="hc-clr-success hc-ma-none hc-pa-none">
                    Your survey has been submitted! Now, you will be redirected
                    to the home page. Thank you!{' '}
                  </p>
                </Grid.Item>
              </>
            )}
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
