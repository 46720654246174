import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history'; //eslint-disable-next-line
import logger from 'redux-logger';
import scannerReducer from './scanner/scannerSlice';
import deviceByStoreReducer from './location/deviceByStoreSlice';
import deviceItemReducer from './location/deviceItemSlice';
import deviceReducer from './location/deviceSlice';
import onboardingReducer from './onboarding/onboardingSlice';
import readerNamesSlice from './onboarding/locationDetailsSlice';
import pinGeneratorReducer from './externalAuth/pinGeneratorSlice';
import authTokenReducer from './externalAuth/authTokenSlice';
import neptuneReducer from './neptune/neptuneSlice';
import calibrationReducer from './calibration/calibrationSlice';
import orientationReducer from './calibration/orientationSlice';
import startCalibrationReducer from './calibration/startCalibrationSlice';
import locationReducer from './calibration/locationSlice';
import lookupDeviceByKeyReducer from './calibration/lookupDeviceByKeySlice';
import vestibuleReducer from './presurvey/surveySlice';
import vestibuleFormReducer from './presurvey/formSlice';
import surveySearchReducer from './presurvey/surveySearchSlice';
import surveyActionReducer from './presurvey/surveyActionSlice';

const initialState = {};

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

export default function setupStore(preloadedState) {
  const store = configureStore({
    reducer: combineReducers({
      calibration: startCalibrationReducer,
      calibrationByDevice: calibrationReducer,
      deviceByStore: deviceByStoreReducer,
      deviceByItem: deviceItemReducer,
      deviceByID: deviceReducer,
      generatedTOTP: pinGeneratorReducer,
      locationCalibration: locationReducer,
      lookupByLogicalName: lookupDeviceByKeyReducer,
      neptune: neptuneReducer,
      onboarding: onboardingReducer,
      orientationCalibration: orientationReducer,
      readerDetailsByStore: readerNamesSlice,
      router: routerReducer,
      scanner: scannerReducer,
      surveyByLocation: surveySearchReducer,
      surveyByAction: surveyActionReducer,
      ui: authTokenReducer,
      vestibuleLocation: vestibuleReducer,
      vestibuleForm: vestibuleFormReducer,
    }),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(routerMiddleware),
    devTools: true,
    /** Uncomment when debugging 
    // middleware: (getDefaultMiddleware) =>
    //   getDefaultMiddleware().concat(routerMiddleware).concat(logger),
    */
    preloadedState,
  });

  return store;
}

export const appStore = setupStore(initialState);

export const history = createReduxHistory(appStore);
// window.store = store; // You can see state of router and store in the console: store.getState()
