import { Route, Routes, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@praxis/component-logging';
import { ProtectedElement } from '@praxis/component-auth';

import apiConfig from '../globalConfig/apiConfig';

import { ErrorContent } from '../components/ErrorContent';
import { Homepage } from '../views/Home/Homepage';
import { InstallationPage } from '../views/Installation/InstallationPage';
import { OnboardingPage } from '../views/Onboarding/OnboardingPage';
import { OnboardingForm } from '../views/Onboarding/OnboardingForm/OnboardingForm';
import { ScannerPage } from '../views/Onboarding/Scanner/ScannerPage';
import { LookupPage } from '../views/Lookup/LookupPage';
import { ResultsPage } from '../views/Lookup/ResultsPage';
import { DeviceInfo } from '../views/Lookup/DeviceInfo';
import { SuccessPage } from '../views/Onboarding/SuccessPage';
import { ActiveItems } from '../views/Active Items/ActiveItems';
import { AdminPage } from '../views/Admin/Admin';
import { SetupLocationPage } from '../views/Calibration/SetupLocationPage';
import { SetupTagPage } from '../views/Calibration/SetupTagPage';
import { SetupReaderPage } from '../views/Calibration/SetupReaderPage';
import { CalibrationPage } from '../views/Calibration/CalibrationPage';
import { FailedCalibrationPage } from '../views/Calibration/FailedCalibrationPage';
import { CompletedCalibrationPage } from '../views/Calibration/CompletedCalibrationPage';
import { Survey } from '../views/Survey/Survey';
import { SurverySuccessPage } from '../views/Survey/SuccessPage';
import { VestibuleTemplate } from '../views/Survey/VestibulePage';
import { ReaderList } from '../views/Survey/ReaderList';
import { SurveySearch } from '../views/Survey Search/SurveySearchPage';
import { LocationList } from '../views/Survey Search/SurveyByLocationList';
import { SurveyDetails } from '../views/Survey Search/VestibuleDetails';
import { TeamMemberTemplate } from '../views/Survey/TMPage';

export const MainRouter = () => {
  const location = useLocation();

  const adminGroups = apiConfig.ADGroups;

  return (
    <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/install" element={<InstallationPage />}></Route>
        <Route path="/onboarding" element={<OnboardingPage />}></Route>
        <Route path="/scan" element={<ScannerPage />}></Route>
        <Route path="/registration" element={<OnboardingForm />}></Route>
        <Route path="/lookup" element={<LookupPage />}></Route>
        <Route path="/results" element={<ResultsPage />}></Route>
        <Route path="/deviceDetails/:device" element={<DeviceInfo />}></Route>
        <Route path="/success" element={<SuccessPage />}></Route>
        <Route path="/activeItems" element={<ActiveItems />}></Route>
        <Route path="/survey" element={<Survey />}></Route>
        <Route path="/survey-success" element={<SurverySuccessPage />}></Route>
        <Route
          path="/survey-teamMember"
          element={<TeamMemberTemplate />}
        ></Route>
        <Route path="/survey-vestibule" element={<VestibuleTemplate />}></Route>
        <Route path="/survey-reader-list" element={<ReaderList />}></Route>
        <Route
          path="/surveySearch"
          element={
            <ProtectedElement component={SurveySearch} allowed={adminGroups} />
          }
        ></Route>
        <Route
          path="/surveyDetails"
          element={
            <ProtectedElement component={SurveyDetails} allowed={adminGroups} />
          }
        ></Route>
        <Route
          path="/locationList"
          element={
            <ProtectedElement component={LocationList} allowed={adminGroups} />
          }
        ></Route>
        <Route
          path="/calibrationSetupLocation"
          element={<SetupLocationPage />}
        ></Route>
        <Route path="/calibrationSetupTag" element={<SetupTagPage />}></Route>

        <Route
          path="/calibrationSetupReader"
          element={<SetupReaderPage />}
        ></Route>

        <Route path="/calibration" element={<CalibrationPage />}></Route>
        <Route
          path="/failedCalibration"
          element={<FailedCalibrationPage />}
        ></Route>
        <Route
          path="/completedCalibration"
          element={<CompletedCalibrationPage />}
        ></Route>
        <Route
          path="/admin"
          element={
            <ProtectedElement component={AdminPage} allowed={adminGroups} />
          }
        ></Route>
      </Routes>
    </ErrorBoundary>
  );
};
