export const Columns = [
  {
    field: 'id',
    filter: true,
    minWidth: 80,
  },
  {
    field: 'vestibule_name',
    filter: true,
    minWidth: 175,
  },
  {
    field: 'floor_id',
    filter: true,
    minWidth: 140,
  },
  {
    field: 'location_id',
    filter: true,
    minWidth: 150,
  },
];
