import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const PATH = API_ROUTES.vestibuleFormSubmit;
const API_KEY = apiConfig.apiKey;

const initialState = {
  form: {},
  loading: false,
  error: null,
};

export const submitNewForm = createAsyncThunk(
  'vestibule/submitForm',
  async (form) => {
    const response = await axios.put(
      `${RYDER_HOST}${PATH}`,
      {
        ...form,
      },
      {
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  },
);

export const submitNewFormExt = createAsyncThunk(
  'vestibule/submitFormExt',
  async (form) => {
    const { formData, token } = form;
    const response = await axios.put(
      `${EXTERNAL_HOST}${PATH}`,
      {
        ...formData,
      },
      {
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'multipart/form-data',
          Authorization: token,
        },
      },
    );
    return response.data;
  },
);

export const formSlice = createSlice({
  name: 'vestibuleForm',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(
        isAnyOf(submitNewForm.fulfilled, submitNewFormExt.fulfilled),

        (state, action) => {
          state.loading = false;
          state.error = null;
          state.form = action.payload;
        },
      )
      .addMatcher(
        isAnyOf(submitNewForm.pending, submitNewFormExt.pending),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(submitNewForm.rejected, submitNewFormExt.rejected),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});

export default formSlice.reducer;

// Selector fns
export const getSubmitForm = (state) => state.vestibuleForm.form;

export const getFormSubmissionDetails = createSelector(
  getSubmitForm,
  (formSubmission) => {
    return formSubmission;
  },
);
