import {
  Card,
  Grid,
  Layout,
  Breadcrumbs,
  Input,
  Heading,
  Spinner,
  Anchor,
} from '@enterprise-ui/canvas-ui-react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  searchByStore,
  searchByStoreExt,
  getSurveyLocationDetails,
} from '../../app/presurvey/surveySearchSlice';
import {
  submitSurveyAction,
  submitSurveyActionExt,
} from '../../app/presurvey/surveyActionSlice';
import {
  Link as ReactLink,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import Select from '@enterprise-ui/canvas-ui-react/components/Input/Select';
import { DataTable, Button } from 'ui-library';
import { Columns } from './columns';
import { useLocation } from 'react-router';
import { useAuth } from '@praxis/component-auth';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';

export const LocationList = () => {
  const [searchParams] = useSearchParams();
  const store = searchParams.get('q');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const auth = useAuth();
  const { user } = useInstallerAuthContext();
  const { session } = auth;
  const [isRejectClicked, setIsRejectClicked] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [surveyStatus, setSurveyStatus] = useState();
  const reader = useSelector(getSurveyLocationDetails);
  const { loading, error } = useSelector((state) => state.surveyByLocation);
  const response = useSelector((state) => state.surveyByAction);

  const handleSubmit = (e, approval) => {
    e.preventDefault();
    let payload = {
      location_id: store,
      survey_result_status: approval,
    };
    if (session) {
      dispatch(submitSurveyAction(payload));
    } else if (user) {
      let { token } = user;
      dispatch(submitSurveyActionExt({ payload, token }));
    }
  };

  useEffect(() => {
    if (store !== null) {
      if (session) {
        dispatch(searchByStore(store));
      } else if (user) {
        let { token } = user;
        dispatch(searchByStoreExt({ store, token }));
      }
    }
  }, [dispatch, store, session, user]);

  const handleClick = (event) => {
    navigate(
      `/surveyDetails?store=${event.data.location_id}&id=${event.data.id}&name=${event.data.vestibule_name}`,
      {
        state: { previousPath: pathname },
      },
    );
  };

  return (
    <>
      <Layout.Body data-testid="LocationList" includeRail>
        <Card className="hc-pa-expanded cardContainer">
          <Grid.Container>
            <Breadcrumbs className="breadcrumbSpacing">
              <Breadcrumbs.Item as={ReactLink} to="/surveySearch">
                Survey Search
              </Breadcrumbs.Item>
              <Breadcrumbs.Item as={ReactLink} to="/survey">
                Survey List
              </Breadcrumbs.Item>
            </Breadcrumbs>
            <Grid.Item xs={12}>
              <Heading size={3}>Survey List</Heading>
              {loading && <Spinner size="sm" />}
              {!loading && !error && (
                <Grid.Item>
                  <>
                    <p
                      className="hc-fs-xs inputPosition"
                      data-testid="surveyResults"
                    >
                      {reader.length} results found for store {store}{' '}
                    </p>
                    <div
                      className="resultsTableWrapper "
                      data-testid="surveyLocationTable"
                    >
                      <DataTable
                        rows={reader}
                        cols={Columns}
                        onRowClicked={handleClick}
                        data-testid="surveyLocationTable"
                      />
                    </div>
                  </>
                </Grid.Item>
              )}
              {error && (
                <Input.Info error>
                  There are no surveys registered to this location.{' '}
                  <Anchor
                    to="/surveySearch"
                    as={ReactLink}
                    className="iot-link"
                  >
                    Please try a different store.
                  </Anchor>
                </Input.Info>
              )}
            </Grid.Item>
            {!isRejectClicked && !isSubmitClicked && !error && !loading && (
              <>
                <Grid.Container
                  className="hc-mb-xl"
                  align="center"
                  justify="center"
                >
                  <Grid.Item>
                    <Button
                      label="Approve Survey"
                      type="submit"
                      name="approve_button"
                      id="approve_button"
                      onClick={(e) => {
                        let approval = 'SURVEY_APPROVED';
                        handleSubmit(e, approval);
                        setIsSubmitClicked(true);
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      label="Reject Survey"
                      type="destructive"
                      name="rejected_button"
                      id="rejected_button"
                      onClick={() => {
                        setIsRejectClicked(true);
                      }}
                    />
                  </Grid.Item>
                </Grid.Container>
              </>
            )}
            {isRejectClicked && (
              <Grid.Container
                align="center"
                justify="center"
                className="hc-mb-sm"
              >
                <Grid.Item>
                  <p className="hc-ma-none"> Reason for being rejected? </p>
                  <Select
                    id="reject_reasoning"
                    name="reject_reasoning"
                    options={[
                      {
                        value: 'CANCELLED',
                        label: 'Cancelled',
                      },
                      {
                        value: 'DUPLICATE_ENTRY',
                        label: 'Duplicate Entry',
                      },
                      {
                        value: 'INFO_REQUIRED_FROM_VENDOR',
                        label: 'Additional Info required from Vendor',
                      },
                      {
                        value: 'INFO_REQUIRED_FROM_TARGET',
                        label: 'Additional Info required from Target',
                      },
                      {
                        value: 'NOT_VIABLE_2025',
                        label: 'Not Viable 2025',
                      },
                      {
                        value: 'PROPERTIES_ENGAGEMENT_REQUIRED',
                        label: 'Properties Engagement Required',
                      },
                      {
                        value: 'SURVEY_REJECTED',
                        label: 'General Rejection/Inaccurate Base Information',
                      },
                    ]}
                    onUpdate={(id, value) => {
                      setSurveyStatus(value);
                    }}
                  />
                </Grid.Item>
                <Grid.Item>
                  <Button
                    className="hc-mt-md"
                    label="Submit"
                    type="submit"
                    name="approve_button"
                    id="approve_button"
                    onClick={(e) => {
                      let approval = surveyStatus;
                      handleSubmit(e, approval);
                      setIsSubmitClicked(true);
                    }}
                    disabled={surveyStatus == null || !surveyStatus}
                  />
                </Grid.Item>
                <Grid.Item>
                  <Button
                    className="hc-mt-md"
                    label="Cancel"
                    type="secondary"
                    name="approve_button"
                    id="approve_button"
                    onClick={() => {
                      setIsRejectClicked(false);
                      setSurveyStatus(null);
                    }}
                  />
                </Grid.Item>
              </Grid.Container>
            )}
            {!response.error && isSubmitClicked && (
              <Grid.Container align="center" justify="center">
                <strong className="hc-clr-success hc-pa-expanded">
                  {' '}
                  Your response has been recorded!{' '}
                </strong>
              </Grid.Container>
            )}
            {response.error && isSubmitClicked && (
              <Grid.Container align="center" justify="center">
                <strong className="hc-clr-error hc-pa-expanded">
                  {response.error}
                </strong>
              </Grid.Container>
            )}
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
