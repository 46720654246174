import { SelectInput } from 'ui-library';
import './styles/selectStyles.scss';

export const Select = ({ label, options, name, ...props }) => {
  return (
    <div className="hc-mb-md">
      <label className="customLabel" htmlFor={name} id={`${name}Label`}>
        {label}
      </label>
      <SelectInput
        options={options}
        name={name}
        aria-labelledby={`${name}Label`}
        classNamePrefix="customSelect"
        {...props}
      />
    </div>
  );
};
