import { Fragment } from 'react';
import { useAuth } from '@praxis/component-auth';

import { Image } from '@enterprise-ui/canvas-ui-react';
import { EnterpriseIcon, ImageIcon } from '@enterprise-ui/icons';
import './styles/imageStyles.scss';

export const ImageCard = ({ imageLink }) => {
  const auth = useAuth();
  const { session } = auth; //if not internal then fallback img

  const encodedLink = encodeURI(imageLink);
  const FallbackImg = (
    <Fragment>
      <div className="customDivPosition">
        <EnterpriseIcon
          icon={ImageIcon}
          size="xl"
          className="customIconPosition"
        />
      </div>
    </Fragment>
  );

  return (
    <div className="customImageStyles" data-testid="imageCard">
      {imageLink && session ? (
        <Image
          width="100%"
          height="100%"
          src={encodedLink}
          alt="Device image"
          type="cover"
          className="rounded-corners"
        />
      ) : (
        FallbackImg
      )}
    </div>
  );
};
